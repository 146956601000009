.card-display {
  border: 1px solid black;
  border-radius:$card-corners;
  width:100%;
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius:$card-corners;
    max-width:100%;
    max-height:100%;
  }
}