// Override default variables before the import
//$body-bg: #000;

@import '~bootstrap/scss/bootstrap.scss';

.col-6 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  margin-top: 50px;
  margin-bottom: 50px;
}