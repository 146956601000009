@import './styles/custom.scss';
@import './styles/variables';
@import './styles/components/App';
@import './styles/components/Field';
@import './styles/components/Deck';
@import './styles/cards';

body {
  margin: 0;
  font-family: "Work Sans","HelveticaNeue","Helvetica Neue",sans-serif;
  font-size: 16px;
  color: $font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  color: $header-color;
  font-weight:600;
  text-transform: uppercase;
  font-size: 1.25em;
  letter-spacing: 0.1em;
  line-height:2em;
}

h2 {
  font-size: 1.25em;
  font-weight:600;
}

a {
  color: #3d4246;
  text-decoration:none;
  border-bottom: 1px solid currentColor;
  padding-bottom:1px;

  &:hover {
    opacity: 0.6;
    color: currentColor;
    text-decoration: none;
  }
}

header {
  margin-top:25px;
  margin-bottom:50px;
  padding-bottom:25px;
  border-bottom: 1px solid #e8e9eb;
  display:flex;
  max-height:100px;
  justify-content: space-between;

  h1 {
    margin-left:50px;
    margin-bottom:0;

    a {
      border-bottom:none;
    }
  }

  .logo {
    margin-right:50px;
    width:100px;

    img {
      height:100%;
      width:100%;
    }
  }
}

footer {
  margin-bottom:25px;
}